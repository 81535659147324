import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import styles from './Routes.module.css';

const WeedingTT = React.lazy(() => import('../pages/WeedingTT'));
const NotFoundPage = React.lazy(() => import('../pages/NotFoundPage'));


export default function Routes() {
  return (
    <React.Suspense fallback={
      <div className={styles.loading} aria-label="Henter invitation...vent venligst">
        <div className={styles.loadicon}>
          <div></div>
          <div></div>
        </div>
      </div>
    }>
      <Router>
        <Switch>
          <Route exact path="/" render={() => <WeedingTT /> } />
          <Route exact path="*" render={() => <NotFoundPage /> } />
        </Switch>
      </Router>
    </React.Suspense>
  )
}